<template>
	<!-- 会员中心 -->
	<div class="finished">
		<div class="project">
			<div class="query-area">
        <h3>公告时间管理</h3>
				<el-form class="" :model="form" label-position="left" label-width="80px" inline ref="form">
					<el-form-item label="行政区">
						<el-cascader
							style="width: 190px"
							v-model="form.xzqbh"
							:props="props"
							:options="regionOptions"
						></el-cascader>
					</el-form-item>
					<div class="button" style="display: inline-block; margin: 0">
						<el-form-item>
							<el-button class="query-btn" type="primary" @click="query">查询</el-button>
							<el-button class="query-btn" type="primary" @click="clear">清空</el-button>
							<el-button class="query-btn" type="primary" @click="add('add')">新增</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="table-con">
				<el-table
					border
					class="table"
					:data="tableData"
					style="width: 100%"
					:row-class-name="handleRowClass"
				>
					<el-table-column align="center" prop="cjggts" label="成交公告天数"></el-table-column>
					<el-table-column align="center" prop="gpggts" label="挂牌公告天数"></el-table-column>
					<el-table-column align="center" prop="jjts" label="最短竞价天数"></el-table-column>
          <el-table-column align="center" prop="jdfmc" label="监督方名称"></el-table-column>
          <el-table-column align="center" prop="jdflxfs" label="监督方联系方式"></el-table-column>
					<el-table-column align="center" prop="xzqFullName" label="行政区名称"></el-table-column>
					<el-table-column align="left" label="操作" width="150">
						<template slot-scope="scope">
							<el-button
								style="width: 46px; height: 28px; padding: 0 8px"
								type="primary"
								size="small"
								@click="add('edit', scope.row)"
								>编 辑</el-button
							>
							<el-button
								v-if="scope.row.bmzt != '4'"
								style="width: 46px; height: 28px; padding: 0 8px"
								type="danger"
								size="small"
								@click="delClick(scope.$index, scope.row)"
								>删 除</el-button
							>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagenation">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="currentPage"
					:page-size="pageSize"
					:total="total"
					@current-change="currentChange"
				>
				</el-pagination>
			</div>
		</div>
		<edit
			ref="ggsjEdit"
			:visible="dialogVisible"
			@close="dialogClose"
			:regionOptions="regionOptions"
			:data="{}"
		></edit>
	</div>
</template>

  <script>
import { mapGetters } from 'vuex'
import { queryGgsjByPg, deleteGgsj } from '@/api/projectManage'
import { getAreaTree } from '@/api/certificate'
import edit from './edit.vue'
export default {
	components: {
		edit,
	},
	data() {
		return {
			form: {
				xzqbh: '',
			},
			typeOptions: [],
			regionOptions: [],
			props: {
				label: 'areaName',
				children: 'children',
				value: 'id',
				fullPathName: 'fullPathName',
				checkStrictly: true,
				emitPath: false,
			},
			tableData: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			examineData: {},
			dialogVisible: false,
		}
	},
	computed: mapGetters(['userInfo']),
	created() {
		this.initData()
		this.getTypes()
		this.getRegions()
	},
	methods: {
		async initData() {
			// 获取数据
			let params = Object.assign(this.form, {
				current: this.currentPage,
				size: this.pageSize,
			})
			let res = await queryGgsjByPg(params)
			let { data, status, message } = res
			if (status == 200) {
				this.tableData = data.records
				this.total = parseInt(data.total)
			}
		},
		query() {
			this.currentPage = 1
			this.initData()
		},
		clear() {
			this.currentPage = 1
			this.form = {
				xzqbh: '',
			}
			this.initData()
		},
		add(type, data) {
			this.dialogVisible = true
			this.$refs.ggsjEdit.type = type
			if (type == 'edit') {
				this.$refs.ggsjEdit.title = '编辑公告时间'
				let { cjggts, gpggts, jjts, jdfmc, jdflxfs, xzqbh, id } = data
				this.$refs.ggsjEdit.form = { cjggts, gpggts, jjts, jdfmc, jdflxfs, xzqbh, id }
			} else {
        this.$refs.ggsjEdit.title = '新增公告时间'
				this.$refs.ggsjEdit.form = {
					cjggts: null,
					gpggts: null,
					jjts: null,
          jdfmc: null,
          jdflxfs: null,
					xzqbh: '',
					id: null,
				}
			}
		},
		handleRowClass({ row, rowIndex }) {
			// 行樣式
			if (rowIndex % 2 === 0) {
				return 'odd'
			}
		},
		getTypes() {
			let cqList = JSON.parse(localStorage.getItem('types'))
			this.typeOptions = cqList.map(x => {
				return {
					label: x.lx,
					value: x.bh,
				}
			})
		},
		showDetail(row) {},
		dialogClose(val) {
			// 关闭弹窗
			this.dialogVisible = val
			this.initData()
		},
		currentChange(val) {
			this.currentPage = val
			this.initData()
		},
		delClick(index, data) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(async () => {
					let res = await deleteGgsj({ ids: [data.id] })
					console.log('删除返回', res)
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getList()
					}
				})
				.catch(() => {
					// this.$message({
					//   type: "info",
					//   message: "已取消删除",
					// });
				})
		},
		async getRegions() {
			// 获取行政区树
			let param = {
				level: '5',
				parentCode: null,
			}
			let res = await getAreaTree(param)
			let { code, data, msg } = res
			if (code == 0) {
				this.regionOptions = data
			} else {
				this.$message.error(msg)
			}
		},
	},
}
</script>

  <style lang="less" scoped>
.query-area {
	padding: 0 30px;
}
.finished {
	text-align: left;
	min-height: 1080px;
	position: relative;
	// margin-top: 46px;
	background: #fff;

	.s-title {
		.title {
			margin-left: 10px;
			font-size: 15px;
			font-weight: 600;
		}
	}
	.project {
		.table-con {
			margin-top: 12px;
			padding: 0 15px 0 18px;
		}
		.tip {
			padding-left: 20px;
			color: #e92f00;
			margin-bottom: 20px;
		}
	}
	.news {
		margin-top: 33px;
	}
	.pagenation {
		// margin-top: 0;
		// position: absolute;
		// right: 20px;
		// bottom: 33px;
		text-align: right;
	}
}
</style>

  <style lang="less">
.finished {
	.el-dialog__wrapper {
		position: absolute;
	}
	.v-modal {
		position: absolute;
	}
	.el-checkbox-button__inner {
		width: 160px;
		border-left: 1px solid #dcdfe6;
	}
	.el-dialog--center .el-dialog__body {
		padding: 25px 60px 30px 38px;
	}
	.el-checkbox-button.is-checked .el-checkbox-button__inner {
		border-radius: 5px;
		border: solid 1px #00a0e9;
		background: #fff;
		color: #00a0e9;
	}
	.el-dialog__footer {
		padding: 10px 20px 45px;
	}
}
</style>
